<template>
  <v-col
          cols="12"
          :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <feed-base-card
            :height="value.prominent ? 450 : 350"
            color="grey lighten-1"
            dark
            @click.stop="goToPost(value)"
    >
      <v-img
              :src="value._embedded['wp:featuredmedia'][0].source_url"
              height="100%"
              gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .20)"
      >
        <v-row
                v-if="!value.prominent"
                class="fill-height text-right ma-0"
        >
          <v-col cols="12">
            <v-chip
                    v-for="(tag, i) in value._embedded['wp:term'][1]"
                    label
                    :key="`tags-${i}`"
                    class="mx-2 mb-2 text-lowercase"
                    color="grey darken-3"
                    text-color="white"
                    small

                    @click.stop=""
            >
              #{{tag.name}}
            </v-chip>

            <h3 class="title font-weight-bold mb-2">
              {{ value.title.rendered }}
            </h3>
            <div class="caption">
              {{ date(value.date )}}
            </div>
          </v-col>

          <v-col align-self="end">
            <v-chip
                    class="text-uppercase ma-0"
                    color="secondary"
                    label
                    small
                    @click="goToPost(value)"
            >
              {{$t('news.readMore')}}
            </v-chip>
          </v-col>
        </v-row>
      </v-img>
    </feed-base-card>
  </v-col>
</template>

<script>
    export default {
        name: 'FeedCard',
        components: {
            FeedBaseCard: () => import('@/components/blog/Card'),
        },
        props: {
            size: {
                type: Number,
                required: true,
            },
            value: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            date (v){
                let dateTime = v.split("T")
                let date = dateTime[0].split("-")
                date.reverse()
                let res = date.join(".")
                return res
            },
            goToPost(val){
                this.$router.push({name:'Post',params:{slug: val.slug}})
            }
        }
    }
</script>

<style>
  .v-image__image {
    transition: .3s linear;
  }
</style>
